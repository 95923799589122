function tsepHandler(eventType, event) {
      setTimeout(function () {
        $('#tsep-cvv2').addClass('form-control');
        $('#tsep-datepicker').addClass('form-control');
        $('#tsep-cardNum').addClass('form-control');
        $('body').css('background-color', '#fff');
        $('html').css('overflow', 'auto');
      }, 1000);
      if (eventType == "TokenEvent") {
        if (event.tsepToken && event.tsepToken.length > 0) {
            var zipCode = $('#zipcode').val();
            if (zipCode != '') {
              var card_number = event.tsepToken;
              var card_type = event.cardType;
              var expiration_date = event.expirationDate;
              var cvc = event.cvv2;
              var cvv2 = cvc.trim();
              data = {
                'card_number': card_number,
                'card_type': card_type,
                'expiration_date': expiration_date,
                'zipCode': zipCode,
                'cvc': cvv2,
                'isError': 0
              };
              // console.log(data);
              window.angularComponentReference.zone.run(() => { window.angularComponentReference.componentFn(data); });
               
            } else {
              $("#div_console").attr("style", "color:red;");
              $('#div_console').html('<p>Zip code can not be empty. Dialog will re-open in <span id="countdowntimer">10 </span> Seconds</p>');
              var timeleft = 10;
              var downloadTimer = setInterval(function () {
                timeleft--;
                document.getElementById("countdowntimer").textContent = timeleft;
                if (timeleft <= 0) {
                  data = {
                    'isError': 1
                  };
                  window.angularComponentDialogReference.zone.run(() => {
                    window.angularComponentDialogReference.componentFn();
                  });
                  // window.location.reload();
                  clearInterval(downloadTimer);
                }
              }, 500);
            }
        } else {
          if (event.responseCode == "TSEPERR911") {
              $("#deviceValidSuccess").attr("style", "display:none");
              $("#domainValidSuccess").attr("style", "display:none");
              $("#manifestValidSuccess").attr("style", "display:none");
              $("#manifestValidFail").attr("style", "display:inline-block");
              $("#tsepToken").val("");
          } else if (event.responseCode == "D2005" || event.responseCode == "TSEP001") {
            $("#div_console").attr("style", "color:red;");
            $('#div_console').html(event.message + '<p> Dialog will re-open in <span id="countdowntimer">10 </span> Seconds</p>');
            var timeleft = 10;
            var downloadTimer = setInterval(function () {
              timeleft--;
              document.getElementById("countdowntimer").textContent = timeleft;
              if (timeleft <= 0) {
                // window.location.reload();
                data = {
                  'isError': 1
                };
                window.angularComponentDialogReference.zone.run(() => {
                  window.angularComponentDialogReference.componentFn();
                });
                clearInterval(downloadTimer);
              }
            }, 500);
          }
        }
      } else if (eventType == "ErrorEvent") {
        //handle ErrorEvent
        if (event.responseCode == "TSEPERR911") {

          $("#deviceValidSuccess").attr("style", "display:none");

          $("#domainValidSuccess").attr("style", "display:none");

          $("#manifestValidSuccess").attr("style", "display:none");

          $("#manifestValidFail").attr("style", "display:inline-block");

          $("#tsepToken").val("");
        }
      } else if (eventType == "FieldValidationErrorEvent") {
        //handle FieldValidationErrorEvent
      } else if (eventType == "FocusOnEvent") {
        //handle FocusOnEvent
      } else if (eventType == "FocusOutEvent") {

        //handle FocusOutEventâ€™
      } else if (eventType == "BlurEvent") {

        //handle BlurEvent
      } else {

      }
    }